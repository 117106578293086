import React from "react";
import { hydrate, render } from "react-dom";
import App from "./App";
import Modal from "react-modal";

Modal.setAppElement("#root");

const container = document.getElementById('root');

if (container.hasChildNodes()) {
    hydrate(<App />, container);
} else {
    render(<App />, container);
}

// const root = createRoot(container);
// root.render(<App />);